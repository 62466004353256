define("discourse/plugins/discourse-verifiable-credentials/discourse/discourse-verifiable-credentials-user-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    path: "users/:username",
    map() {
      this.route("credentials", function () {
        this.route("records");
      });
    }
  };
});