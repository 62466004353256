define("discourse/plugins/discourse-verifiable-credentials/discourse/routes/user-credentials-records", ["exports", "@ember/routing/route", "discourse/plugins/discourse-verifiable-credentials/discourse/models/user-credential"], function (_exports, _route, _userCredential) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {
      return _userCredential.default.findAll();
    },
    setupController(controller, model) {
      const hasErrors = model.some(record => record.error);
      controller.setProperties({
        hasErrors,
        model
      });
    }
  });
});