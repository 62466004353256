define("discourse/plugins/discourse-verifiable-credentials/discourse/helpers/short-did", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.registerUnbound)("short-did", function (did) {
    if (!did || did.length < 3) {
      return null;
    }
    return did.substring(0, 25) + "...";
  });
});