define("discourse/plugins/discourse-verifiable-credentials/discourse/templates/connectors/user-main-nav/credentials", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (user-viewing-self model)}}
    {{#link-to "user.credentials"}}{{d-icon "passport"}}{{I18n "verifiable_credentials.user.label"}}{{/link-to}}
  {{/if}}
  
  */
  {
    "id": "Yo2xVnsf",
    "block": "[[[41,[28,[37,1],[[30,0,[\"model\"]]],null],[[[1,\"  \"],[6,[39,2],[\"user.credentials\"],null,[[\"default\"],[[[[1,[28,[35,3],[\"passport\"],null]],[1,[28,[35,4],[\"verifiable_credentials.user.label\"],null]]],[]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-verifiable-credentials/discourse/templates/connectors/user-main-nav/credentials.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"user-viewing-self\",\"link-to\",\"d-icon\",\"I18n\"]]",
    "moduleName": "discourse/plugins/discourse-verifiable-credentials/discourse/templates/connectors/user-main-nav/credentials.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});