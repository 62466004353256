define("discourse/plugins/discourse-verifiable-credentials/discourse/models/user-credential", ["exports", "@ember/object", "discourse/lib/ajax"], function (_exports, _object, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserCredential = _object.default.extend();
  UserCredential.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/vc/user/records.json");
    }
  });
  var _default = _exports.default = UserCredential;
});