define("discourse/plugins/discourse-verifiable-credentials/discourse/helpers/user-viewing-self", ["exports", "discourse-common/lib/helpers", "discourse/models/user"], function (_exports, _helpers, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helpers.registerUnbound)("user-viewing-self", function (model) {
    if (_user.default.current()) {
      return _user.default.current().username.toLowerCase() === model.username.toLowerCase();
    }
    return false;
  });
});