define("discourse/plugins/discourse-verifiable-credentials/discourse/routes/user-credentials-index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    redirect() {
      this.transitionTo("user.credentials.records");
    }
  });
});