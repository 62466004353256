define("discourse/plugins/discourse-verifiable-credentials/discourse/widgets/verifiable-credentials-presentation-button", ["exports", "discourse/widgets/widget", "discourse/plugins/discourse-verifiable-credentials/discourse/lib/credentials"], function (_exports, _widget, _credentials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("verifiable-credentials-presentation-button", {
    tagName: "div.verifiable-credentials-presentation-button",
    html() {
      return this.attach("button", {
        action: "verifyCredentials",
        className: "verify-request btn-primary",
        icon: "passport",
        label: "verifiable_credentials.button.label"
      });
    },
    verifyCredentials() {
      (0, _credentials.verify)(this.currentUser, this.attrs.resources, this.siteSettings);
    }
  });
});