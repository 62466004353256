define("discourse/plugins/discourse-verifiable-credentials/discourse/lib/credentials", ["exports", "discourse/plugins/discourse-verifiable-credentials/discourse/lib/resources", "discourse/lib/show-modal"], function (_exports, _resources, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = verify;
  const encodeParams = p => Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
  function verify(user, resources, siteSettings) {
    const provider = siteSettings.verifiable_credentials_provider;
    if (!provider || !resources) {
      return;
    }
    const oidc = siteSettings.verifiable_credentials_oidc;
    const resourcesString = (0, _resources.joinResources)(resources);
    if (oidc) {
      let params = {
        resources: resourcesString,
        provider
      };
      let url = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
      let path = `/vc/presentation/${provider}/initiate?` + encodeParams(params);
      window.location.href = url + path;
    } else {
      const controller = (0, _showModal.default)("verifiable-credentials-presentation", {
        model: {
          resources: resourcesString
        }
      });
      controller.perform();
    }
  }
});