define("discourse/plugins/discourse-verifiable-credentials/discourse/components/claims-list", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    inputDelimiter: "|",
    actions: {
      onChange(claims) {
        this.set("claims", claims.join(this.inputDelimiter));
      }
    }
  });
});