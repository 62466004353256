define("discourse/plugins/discourse-verifiable-credentials/discourse/widgets/verifiable-credentials-header-panel", ["exports", "discourse/widgets/widget", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/url", "I18n"], function (_exports, _widget, _virtualDom, _iconLibrary, _url, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("verifiable-credentials-header-panel", {
    tagName: "div.verifiable-credentials-header-panel",
    html() {
      return this.attach("menu-panel", {
        maxWidth: 300,
        contents: () => this.panelContents()
      });
    },
    panelContents() {
      const resourceDescriptions = this.resourceDescriptions();
      const resources = this.attrs.resources;
      return (0, _virtualDom.h)("div.verifiable-credentials-header-panel-contents", [this.attach("verifiable-credentials-presentation-button", {
        resources
      }), (0, _virtualDom.h)("ul", resourceDescriptions.map(description => {
        return (0, _virtualDom.h)("li", [(0, _iconLibrary.iconNode)(description.icon), (0, _virtualDom.h)("span", description.text)]);
      })), (0, _virtualDom.h)("hr"), (0, _virtualDom.h)("div.footer-links", this.footerLinks())]);
    },
    footerLinks() {
      let links = [this.attach("link", {
        icon: "user",
        label: "verifiable_credentials.header.yours",
        actionParam: (0, _url.userPath)(this.currentUser.username + "/credentials/records"),
        action: "goToLink"
      })];
      const infoUrl = this.siteSettings.verifiable_credentials_header_info_url;
      if (infoUrl) {
        links.push(this.attach("link", {
          icon: "info-circle",
          label: "verifiable_credentials.header.info",
          actionParam: infoUrl,
          action: "goToLink"
        }));
      }
      return links;
    },
    resourceDescriptions() {
      const resources = this.attrs.resources;
      const groups = this.attrs.groups;
      const badges = this.attrs.badges;
      const resourceIcons = {
        badge: "certificate",
        group: "users"
      };
      return resources.map(resource => {
        let textAttrs = {};
        if (resource.type === "group") {
          textAttrs.group_name = groups.find(group => group.id === resource.id).full_name;
        }
        if (resource.type === "badge") {
          textAttrs.badge_name = badges.find(badge => badge.id === resource.id).name;
        }
        return {
          icon: resourceIcons[resource.type],
          text: _I18n.default.t(`verifiable_credentials.header.resource.${resource.type}.description`, textAttrs)
        };
      });
    },
    mouseDownOutside() {
      this.sendWidgetAction("toggleVcPanelVisible");
    },
    goToLink(url) {
      _url.default.routeTo(url);
      this.sendWidgetAction("toggleVcPanelVisible");
    }
  });
});