define("discourse/plugins/discourse-verifiable-credentials/discourse/components/resource-label", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/lib/url", "I18n"], function (_exports, _component, _decorators, _url, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":resource-label", "resource.type", "resource.verified:verified"],
    attributeBindings: ["title"],
    title(type, name, verified) {
      const key = verified ? "verified" : "not_verified";
      return _I18n.default.t(`verifiable_credentials.user.records.resources.${type}.${key}`, {
        name
      });
    },
    resourceIcon(type) {
      return {
        group: "users",
        badge: "certificate"
      }[type];
    },
    verifiedIcon(verified) {
      return verified ? "check" : "times";
    },
    model(type, id) {
      if (type === "group") {
        const groups = this.site.credential_groups;
        return groups.find(g => g.id === id);
      }
      if (type === "badge") {
        const badges = this.currentUser.verifiable_credential_badges;
        return badges.find(b => b.id === id);
      }
    },
    click() {
      const resource = this.resource;
      const model = this.model;
      let url = "#";
      if (resource.type === "group") {
        url = `/g/${model.name}`;
      }
      if (resource.type === "badge") {
        url = `/badges/${model.id}/${model.name}`;
      }
      _url.default.routeTo(url);
    }
  }, [["method", "title", [(0, _decorators.default)("resource.type", "model.name", "resource.verified")]], ["method", "resourceIcon", [(0, _decorators.default)("resource.type")]], ["method", "verifiedIcon", [(0, _decorators.default)("resource.verified")]], ["method", "model", [(0, _decorators.default)("resource.type", "resource.id")]]]));
});