define("discourse/plugins/discourse-verifiable-credentials/discourse/initializers/verifiable-credentials-initializer", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-verifiable-credentials/discourse/lib/resources", "discourse/lib/url", "bootbox", "I18n"], function (_exports, _pluginApi, _resources, _url, _bootbox, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "verifiable-credentials",
    initialize(container) {
      const messageBus = container.lookup("message-bus:main");
      const siteSettings = container.lookup("site-settings:main");
      const site = container.lookup("site:main");
      const currentUser = container.lookup("current-user:main");
      messageBus.subscribe("/vc/verification-complete", function (redirectUrl) {
        const href = redirectUrl ? redirectUrl : "/";
        window.location.href = href;
      });
      (0, _pluginApi.withPluginApi)("0.8.30", api => {
        api.modifyClass("model:group", {
          pluginId: ["verifialbe-credentials"],
          custom_fields: {},
          asJSON() {
            return Object.assign(this._super(...arguments), {
              custom_fields: this.custom_fields
            });
          }
        });
        api.modifyClass("route:group", {
          pluginId: ["verifialbe-credentials"],
          activate() {
            const params = new Proxy(new URLSearchParams(window.location.search), {
              get: (searchParams, prop) => searchParams.get(prop)
            });
            if (params.failed_to_verify === "true") {
              _bootbox.default.alert(_I18n.default.t("verifiable_credentials.present.failed"));
              window.history.replaceState(null, null, window.location.pathname);
            }
          }
        });
        api.attachWidgetAction("header", "toggleVcPanelVisible", function () {
          this.state.vcPanelVisible = !this.state.vcPanelVisible;
          this.scheduleRerender();
        });
        const groups = (0, _resources.headerGroups)(siteSettings, site, currentUser);
        const badges = (0, _resources.credentialBadges)(siteSettings, site, currentUser);
        let resources = [...(0, _resources.mapResource)(groups, "group"), ...(0, _resources.mapResource)(badges, "badge")];
        api.addHeaderPanel("verifiable-credentials-header-panel", "vcPanelVisible", function () {
          return {
            resources,
            groups,
            badges
          };
        });
        if (siteSettings.verifiable_credentials_header && resources.length) {
          api.addToHeaderIcons("verifiable-credentials-header-button");
        }
        if (currentUser) {
          api.addQuickAccessProfileItem({
            icon: "passport",
            href: (0, _url.userPath)(currentUser.username + "/credentials/records"),
            content: _I18n.default.t("verifiable_credentials.user.label")
          });
        }
      });
    }
  };
});