define("discourse/plugins/discourse-verifiable-credentials/discourse/widgets/verifiable-credentials-header-button", ["exports", "discourse/widgets/widget", "discourse-common/lib/icon-library", "discourse/lib/url", "virtual-dom", "discourse/lib/intercept-click", "I18n"], function (_exports, _widget, _iconLibrary, _url, _virtualDom, _interceptClick, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("verifiable-credentials-header-button", {
    tagName: "li.header-dropdown-toggle.verifiable-credentials-header-button",
    html() {
      const headerWidget = this.headerWidget();
      return (0, _virtualDom.h)("a.icon", {
        attributes: {
          "aria-haspopup": true,
          "aria-expanded": headerWidget.state.vcPanelVisible,
          href: (0, _url.userPath)(this.currentUser.username + "/credentials/records"),
          title: _I18n.default.t("verifiable_credentials.header.button.title"),
          "data-auto-route": true
        }
      }, (0, _iconLibrary.iconNode)("passport"));
    },
    buildClasses() {
      const headerWidget = this.headerWidget();
      let classes = [];
      if (headerWidget.state.vcPanelVisible) {
        classes.push("active");
      }
      return classes;
    },
    click(e) {
      if ((0, _interceptClick.wantsNewWindow)(e)) {
        return;
      }
      e.preventDefault();
      if (!this.attrs.active) {
        this.sendWidgetAction("toggleVcPanelVisible");
      }
    },
    headerWidget() {
      return this.parentWidget.parentWidget;
    }
  });
});