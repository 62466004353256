define("discourse/plugins/discourse-verifiable-credentials/discourse/templates/components/verifiable-credentials-group-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showButton}}
    {{verifiable-credentials-presentation-button resources=resources}}
  {{/if}}
  
  */
  {
    "id": "hb0f8rl5",
    "block": "[[[41,[30,0,[\"showButton\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"resources\"],[[30,0,[\"resources\"]]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showButton` property path was used in the `discourse/plugins/discourse-verifiable-credentials/discourse/templates/components/verifiable-credentials-group-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showButton}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `resources` property path was used in the `discourse/plugins/discourse-verifiable-credentials/discourse/templates/components/verifiable-credentials-group-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.resources}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"verifiable-credentials-presentation-button\"]]",
    "moduleName": "discourse/plugins/discourse-verifiable-credentials/discourse/templates/components/verifiable-credentials-group-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});