define("discourse/plugins/discourse-verifiable-credentials/discourse/components/verifiable-credentials-presentation-button", ["exports", "@ember/component", "discourse/plugins/discourse-verifiable-credentials/discourse/lib/credentials"], function (_exports, _component, _credentials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["verifiable-credentials-presentation-button"],
    actions: {
      verifyCredentials() {
        (0, _credentials.verify)(this.currentUser, this.resources, this.siteSettings);
      }
    }
  });
});