define("discourse/plugins/discourse-verifiable-credentials/discourse/components/verifiable-credentials-group-button", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    canAccess(membershipByVC, userIsGroupUser) {
      return membershipByVC && !userIsGroupUser;
    },
    showButton(canAccess, showButton) {
      return canAccess && showButton;
    },
    resources() {
      let resources = [{
        type: "group",
        id: this.group.id
      }];
      return resources;
    }
  }, [["method", "canAccess", [(0, _decorators.default)("group.custom_fields.verifiable_credentials_allow_membership", "group.is_group_user")]], ["method", "showButton", [(0, _decorators.default)("canAccess", "group.custom_fields.verifiable_credentials_show_button")]], ["method", "resources", [_decorators.default]]]));
});