define("discourse/plugins/discourse-verifiable-credentials/discourse/templates/components/verifiable-credentials-presentation-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
    action=(action "verifyCredentials")
    class="verify-request btn-primary"
    icon="passport"
    label="verifiable_credentials.button.label"}}
  
  */
  {
    "id": "VoWGZPV+",
    "block": "[[[1,[28,[35,0],null,[[\"action\",\"class\",\"icon\",\"label\"],[[28,[37,1],[[30,0],\"verifyCredentials\"],null],\"verify-request btn-primary\",\"passport\",\"verifiable_credentials.button.label\"]]]],[1,\"\\n\"]],[],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-verifiable-credentials/discourse/templates/components/verifiable-credentials-presentation-button.hbs",
    "isStrictMode": false
  });
});