define("discourse/plugins/discourse-verifiable-credentials/discourse/templates/user/credentials", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#d-section pageClass="user-credentials" class="user-secondary-navigation" scrollTop="false"}}
    {{#mobile-nav
      class="activity-nav"
      desktopClass="action-list nav-stacked"
      currentPath=router._router.currentPath
    }}
      <li>
        {{#link-to "user.credentials.records"}}
          {{i18n "verifiable_credentials.user.records.label"}}
        {{/link-to}}
      </li>
    {{/mobile-nav}}
  {{/d-section}}
  
  <section class="user-content">
    {{outlet}}
  </section>
  
  */
  {
    "id": "ZxtSfEax",
    "block": "[[[6,[39,0],null,[[\"pageClass\",\"class\",\"scrollTop\"],[\"user-credentials\",\"user-secondary-navigation\",\"false\"]],[[\"default\"],[[[[6,[39,1],null,[[\"class\",\"desktopClass\",\"currentPath\"],[\"activity-nav\",\"action-list nav-stacked\",[30,0,[\"router\",\"_router\",\"currentPath\"]]]],[[\"default\"],[[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,2],[\"user.credentials.records\"],null,[[\"default\"],[[[[1,\"        \"],[1,[28,[35,3],[\"verifiable_credentials.user.records.label\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]]],[]]]]],[1,\"\\n\"],[10,\"section\"],[14,0,\"user-content\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `router` property path was used in the `discourse/plugins/discourse-verifiable-credentials/discourse/templates/user/credentials.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.router}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-section\",\"mobile-nav\",\"link-to\",\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-verifiable-credentials/discourse/templates/user/credentials.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});